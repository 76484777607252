// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-categories-articles-js": () => import("./../../../src/pages/categories/articles.js" /* webpackChunkName: "component---src-pages-categories-articles-js" */),
  "component---src-pages-categories-case-studies-js": () => import("./../../../src/pages/categories/case-studies.js" /* webpackChunkName: "component---src-pages-categories-case-studies-js" */),
  "component---src-pages-categories-projects-js": () => import("./../../../src/pages/categories/projects.js" /* webpackChunkName: "component---src-pages-categories-projects-js" */),
  "component---src-pages-categories-tutorials-js": () => import("./../../../src/pages/categories/tutorials.js" /* webpackChunkName: "component---src-pages-categories-tutorials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-templates-collection-collection-js": () => import("./../../../src/templates/collection/collection.js" /* webpackChunkName: "component---src-templates-collection-collection-js" */),
  "component---src-templates-contributor-contributor-js": () => import("./../../../src/templates/contributor/contributor.js" /* webpackChunkName: "component---src-templates-contributor-contributor-js" */)
}

